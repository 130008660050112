.MultiSeller_two .home {
    background: #fff;
}

.siteBanner {
    width: 100%;
    height: 550px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.filters-by,
.sort-by {
    margin: 0 -5px;
}

.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.MultiSeller_two .home .auctionListCnt {
    position: relative;
    margin-top: 0px;
    background: #fff;
    border-radius: 0px;
}

.MultiSeller_two .home .auctionListCnt .auctionListTitle {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2e2e2e;
    text-align: left;
    text-transform: initial;
    margin: 45px 0;
    position: relative;
    text-align: center;
}

/* .MultiSeller_two .home .auctionListCnt .auctionListTitle::before {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    width: 15px;
    transform: translateX(-110px);
    background: var(--primColor);
    height: 1px;
}

.MultiSeller_two .home .auctionListCnt .auctionListTitle::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    right: 50%;
    width: 15px;
    transform: translateX(110px);
    background: var(--primColor);
    height: 1px;
} */

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.MultiSeller_two .home .siteBanner .bannerContent {
    position: absolute;
    top: 120px;
    left: 60px;
    /* display: none;*/
}

.MultiSeller_two.bidNaa .home .siteBanner .bannerContent .banerTit {
    font-size: 36px;
    color: #000;
    margin-bottom: 15px;
}

.MultiSeller_two.bidNaa .home .siteBanner .bannerContent .bannerHdr {
    font-size: 56px;
    font-family: serif;
    font-style: normal;
    color: #000;
    line-height: 60px;
    margin-bottom: 35px;
}

.MultiSeller_two.bidNaa .home .siteBanner .bannerContent button {
    border-radius: 6px;
    width: auto;
    text-transform: uppercase;
    font-size: 18px;
    padding: 12px 30px;
    height: auto;
}

.MultiSeller_two .home .siteBanner .bannerContent h2 {
    font-size: 34px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 500px;
    width: 100%;
    /* line-height: 50px; */
    min-height: 170px;
}

.MultiSeller_two .bannerContent .primButton {
    margin-top: 10px;
}

.MultiSeller_two .home .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.MultiSeller_two .home .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.MultiSeller_two .homeCnt {
    display: flex;
    justify-content: flex-start;
}

.MultiSeller_two .homeCnt .homeLt {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
    margin-top: 210px;
}

.MultiSeller_two .homeCnt .homeRt {
    width: 100%;
    max-width: 100%;
}

.MultiSeller_two .homeLt h3 {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 25px;
    position: relative;
}

.MultiSeller_two .homeLt h3::after {
    position: absolute;
    content: '';
    bottom: -10px;
    left: 0;
    height: 3px;
    width: 80px;
    background: var(--primColor);
}

.MultiSeller_two .homeLt .homeCatg {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}

.MultiSeller_two .homeLt .homeCatg .homeCatgInner .MuiCheckbox-root {
    display: none;
}

.MultiSeller_two .homeLt .homeCatg .homeCatgInner > div {
    width: 100%;
}

.MultiSeller_two .homeLt .homeCatg .homeCatgInner > div > .customCheckbox,
.MultiSeller_two .homeLt .homeCatg label,
.MultiSeller_two .homeLt .homeCatg .MuiTypography-root {
    width: 100%;
}

.MultiSeller_two .homeLt .homeCatg .homeCatgInner .catCount {
    height: 25px;
    font-size: 14px;
    background: #e9e9e9;
    color: #2e2e2e;
    padding: 0px 15px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.MultiSeller_two .homeLt .homeCatg .homeCatgInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MultiSeller_two .homeLt .homeCatg .homeCatgInner label {
    margin: 0;
}

.MultiSeller_two .homeRt .MuiTabs-fixed {
    text-align: center;
}

.MultiSeller_two .homeRt .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.MultiSeller_two .homeRt .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.MultiSeller_two .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.MultiSeller_two .homeRt .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: var(--secColor);
}

.MultiSeller_two .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--secColor);
}

.MultiSeller_two .homeRt .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.MultiSeller_two .homeRt .MuiTabs-root {
    margin-bottom: 45px;
}

.MultiSeller_two .homeContact {
    text-align: center;
    background: #f7f7f7;
    padding: 45px 0;
}

.MultiSeller_two .homeContact h2 {
    font-size: 22px;
    font-weight: 600;
    color: #2e2e2e;
    margin-bottom: 10px;
}

.MultiSeller_two .homeContact p {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
    margin-bottom: 20px;
}

.MultiSeller_two .homeContact .subsBox {
    height: 50px;
    width: 400px;
    margin: 0 auto;
}

.MultiSeller_two .homeContact .subsBox input {
    height: inherit;
    border: none;
    width: calc(100% - 50px);
    padding-left: 15px;
}

.MultiSeller_two .homeContact .subsBox button {
    height: inherit;
    background: var(--secColor);
    color: #fff;
    min-width: inherit;
    width: 50px;
}

.homeCnt .toggleRespDrawer.MuiButton-root {
    display: none;
    z-index: 100;
}

.home .viewAllBtn {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 10px 0;
}

.home .viewAllBtn hr {
    width: 45%;
}

.home .viewAllBtn button {
    color: var(--primColor);
    text-transform: initial;
    min-width: max-content;
}

.homeLoader .loadingCnt {
    display: block;
}

.homeLoader .loadingCnt .skeletonWrapper {
    box-shadow: none;
    margin: 15px;
    padding: 0;
}

.homeLoader .loadingCnt .listViewSkeleton {
    background: #ffffff;
}

.homeAuctions.mobileView {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.homeAuctions.mobileView .MultiSeller_twoTimerView h6:not(:last-child) {
    margin-right: 20px;
}

.homeAuctions.mobileView .MultiSeller_twoTimerView h6 {
    font-size: 18px;
}

.homeAuctions.mobileView .MultiSeller_two .grid-card .list-title {
    font-size: 15px;
    height: 4.5ex;
}

@media (min-width: 600px) and (max-width: 768px) {
    .MultiSeller_two .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}

/* Responsive Code */

@media (min-width: 1024px) {
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .MultiSeller_two .homeCnt .homeRt {
        max-width: 100%;
    }

    .MultiSeller_two .home .auctionListCnt .auctionListTitle {
        text-align: left;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
        position: absolute;
        top: 72px;
        right: 60px;
        left: auto;
    }

    .MultiSeller_two .homeCnt .homeLt {
        display: none;
    }
}

@media (max-width: 850px) {
    .MultiSeller_two .homeRt .MuiTab-wrapper {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .MultiSeller_two.bidNaa .home .siteBanner .bannerContent {
        position: absolute;
        top: 20px;
        left: 35px;
    }

    .MultiSeller_two.bidNaa .home .siteBanner .bannerContent .banerTit {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .MultiSeller_two.bidNaa .home .siteBanner .bannerContent button {
        font-size: 14px;
        padding: 6px 20px;
        height: auto;
    }

    .MultiSeller_two.bidNaa .home .siteBanner .bannerContent .bannerHdr {
        font-size: 22px;
        margin-bottom: 5px;
        line-height: normal;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        top: 50px;
    }

    .MultiSeller_two .bannerContent .primButton {
        margin-top: 20px;
    }

    .siteBanner {
        margin-bottom: 0;
    }

    .MultiSeller_two .homeRt .MuiTabs-fixed {
        text-align: left;
    }

    .MultiSeller_two .home .auctionListCnt .auctionListTitle {
        margin: 20px auto;
    }

    .MultiSeller_two .homeCnt .homeLt {
        max-width: 100%;
        position: inherit;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .MultiSeller_two .homeCnt .homeRt {
        max-width: 100%;
    }

    .MultiSeller_two .homeCnt {
        flex-direction: column;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .MultiSeller_two.bidNaa .siteBanner {
        height: 200px;
    }

    .MultiSeller_two .siteBanner {
        height: 175px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .Liquidation .home .siteBanner .bannerContent {
        top: 30px;
        left: 30px;
    }

    .Liquidation .home .siteBanner .bannerContent h2 {
        font-size: 25px;
    }

    .Liquidation .home .siteBanner .bannerContent p {
        font-size: 15px;
        margin-bottom: 25px;
    }

    /* .Liquidation .home .auctionListCnt {
        margin-top: -90px;
    } */
    .Liquidation .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .MultiSeller_two .homeRt .MuiTab-wrapper {
        font-size: 16px;
    }

    .MultiSeller_two .homeRt .MuiTabs-fixed {
        overflow: scroll !important;
    }

    .MultiSeller_two .home .siteBanner .bannerContent {
        top: 60px;
    }
}

@media (max-width: 600px) {
    .MultiSeller_two .home .auctionListCnt .auctionListTitle {
        font-size: 22px;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 30px;
    }

    .MultiSeller_two .home .siteBanner .bannerContent h2 {
        font-size: 18px;
        margin-bottom: 10px;
        max-width: 100%;
        width: 100%;
        line-height: 35px;
        min-height: 80px;
        padding-bottom: 10px !important;
    }

    .MultiSeller_two .home .siteBanner .bannerContent {
        position: absolute;
        top: 18px;
        left: 25px;
    }

    .home .viewAllBtn hr {
        width: 35%;
    }
}

@media (max-width: 475px) {
    .MultiSeller_two .homeContact .subsBox {
        width: 90%;
    }

    .MultiSeller_two .homeContact {
        padding: 25px 0;
    }

    .MultiSeller_two .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 15px;
        padding: 3px 10px;
    }

    .MultiSeller_twoTimerView h6 {
        font-size: 15px;
    }

    .MultiSeller_twoTimerView h6:not(:last-child) {
        margin-right: 20px;
    }

    .MultiSeller_two .grid-card .list-title {
        font-size: 16px;
    }
}

@media (max-width: 450px) {
    .MultiSeller_two .siteBanner {
        height: 170px;
        top: 40%;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }

    .Liquidation .home .auctionListCnt {
        padding: 0;
        margin-top: 0;
    }

    .Liquidation .home {
        background: #fff;
    }
}
