:root {
    --primColor: #000;
    --secColor: #795050;
    --tertColor: #eee;
    --headerColor: #eee;
    --headerFontColor: #333;
    --footerColor: #eee;
    --footerFontColor: #333;
    --bannerTextColor: #333;
    --bgColor: 'red';
    --primFont: 'Open Sans';
    --titleFont: '';
    --btnBorderRadius: '5';
    --siteName: 'Test Preview';
    --logo: '';
    --linerGadient: linear-gradient(to right, #000000, #000000);
}

div.MuiSnackbarContent-message {
    width: 100%;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--primFont), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    /* background: #2b2b2be0; */
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea,
.cke.cke_reset {
    margin-bottom: 30px;
    /* width: 100%; */
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: #000;
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: #000;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
    width: 100%;
}

.MuiFormLabel-root {
    text-align: left;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--primFont) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.primButton button {
    color: #fff;
    border-radius: 3px;
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
}

.primeBids .primButton button.Mui-disabled {
    color: #fff;
}

.primeBids .primButton button {
    background: var(--linerGadient);
    border: 1px solid #000;
}

.primButton button:hover {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: #fff;
    opacity: '0.9';
    box-shadow: none;
}

.fullBasketPrimBtn button {
    background-color: #875486 !important;
    border: 1px solid #875486 !important;
}

.secButton button {
    color: var(--primColor);
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid var(--primColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
}

.secButton button:hover {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: #fff;
    box-shadow: none;
}

.LotsAuction .secButton button,
.LotsAuction button.cancelButton {
    color: white;
    background: var(--secColor) !important;
    border: 1px solid var(--secColor);
}

.LotsAuctio .secButton button:hover,
.LotsAuction button.cancelButton:hover {
    background: #fff !important;
    border: 1px solid var(--secColor);
    color: var(--secColor);
    box-shadow: none;
}

.primButton button,
.secButton button,
.tertButton button {
    height: 50px;
    margin: 0px;
}

.LotsAuction .primButton button,
.LotsAuction .secButton button,
.LotsAuction .tertButton button,
.LotsAuction button.cancelButton {
    border-radius: 8px;
    text-transform: capitalize;
}

.primButton.small button,
.secButton.small button,
.tertButton.small button {
    height: 40px;
    min-width: max-content;
}

.primButton.large button,
.secButton.large button,
.tertButton.large button {
    height: 60px;
}

.Automobile .secButton button {
    border-radius: 0;
}

@media (max-width: 500px) {
    .ArtAuction
        .customSelect
        .MuiFormControl-root.MuiTextField-root
        .MuiSelect-outlined.MuiSelect-outlined {
        padding: 12px 14px;
    }
    .primButton.small button,
    .secButton.small button,
    .tertButton.small button {
        height: 40px;
        font-size: 13px;
    }

    .primButton button,
    .secButton button,
    .tertButton button {
        height: 45px;
        font-size: 14px;
    }

    .primButton.large button,
    .secButton.large button,
    .tertButton.large button {
        height: 50px;
        font-size: 14px;
    }
    .home .homeBanner .hbLt .bannerSearch input {
        height: 50px;
    }

    .LotsAuction .primButton button,
    .LotsAuction .secButton button,
    .LotsAuction .tertButton button {
        font-size: 13px;
    }
}
