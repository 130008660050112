.LotsAuction .pv-media .media-left {
    max-width: 545px;
    width: auto;
    margin-right: 40px;
}

.LotsAuction .pv-media .image-gallery-content .image-gallery-slide .image-gallery-image {
    /* max-height: 345px; */
    max-width: initial;
    width: 545px;
    height: 345px;
    object-fit: contain;
    border-radius: 10px;
}

.LotsAuction .pv-media .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: contain !important;
}

.LotsAuction .bidHstryModal .custom-modal .modal-content {
    max-height: 70vh;
}

.LotsAuction .pv-media .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.LotsAuction .tmm {
    background: #ba122b;
    color: #fff;
    padding: 3px 10px;
    border-radius: 4px;
    display: block;
    width: max-content;
    margin: 0 auto;
}

.LotsAuction .pv-media .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
}

.LotsAuction .pv-media .image-gallery-thumbnail {
    width: 108px;
}

.LotsAuction .pv-media .image-gallery-thumbnail.active,
.LotsAuction .pv-media .image-gallery-thumbnail:hover,
.LotsAuction .pv-media .image-gallery-thumbnail:focus {
    border: 4px solid var(--primColor);
    border-radius: 8px;
}

.LotsAuction .pv-media .image-gallery-thumbnails-container {
    text-align: left;
}

.LotsAuction .pv-title {
    font-weight: 600;
    font-size: 22px;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
}

.LotsAuction .pv-con {
    font-size: 16px;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
}

.LotsAuction .pv-con.aucDesc {
    margin-bottom: 5px;
}

.LotsAuction .proDesct ul {
    width: 100% !important;
}

.LotsAuction .pv-con1 {
    font-size: 18px;
    color: #000;
    margin: 10px 0;
    line-height: 30px;
    font-weight: 500;
    min-width: 200px;
}

.LotsAuction .pv-con span {
    font-weight: 600;
}

.LotsAuction .mt-100 {
    margin-top: 90px;
}

.LotsAuction .pbid-igroup .primary-btn {
    width: 200px;
    height: 50px;
    margin-left: 15px;
    min-width: 200px;
}

.LotsAuction .pbid-igroup .bda-input input {
    padding-right: 40px;
}

.LotsAuction .pbid-igroup .bda-input {
    position: relative;
    max-width: 250px;
    margin-left: 25px;
}

.LotsAuction .ig-addon {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 34px;
    height: 48px;
    border-radius: 0px 5px 5px 0px;
    background: #ebebff;
    padding: 8px 5px;
    text-align: center;
    font-size: 20px;
}

.LotsAuction a.read-more {
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    color: #afafaf !important;
    margin-left: 5px;
}

.LotsAuction .tb-link {
    color: var(--primColor) !important;
    cursor: pointer !important;
}

.LotsAuction .time-left {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #000;
    margin: 15px 0 30px;
}

.LotsAuction .time-left img {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
}

.LotsAuction .bn-con {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #000;
    margin-bottom: 15px;
}

.LotsAuction .bn-con span {
    color: var(--primColor);
}

.LotsAuction .bnow-form {
    max-width: 275px;
    margin: 35px auto;
    display: block;
}

.LotsAuction .bnow-form .primary-btn {
    width: 100%;
}

.LotsAuction .cancel-bid {
    font-size: 18px;
    text-align: left;
    color: #afafaf !important;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    text-transform: uppercase;
}

.LotsAuction .won-msg,
.LotsAuction .outbid-msg,
.LotsAuction .hb-msg {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 18px;
    text-align: center;
    background: #ffecd9;
    color: #ff8801;
    padding: 10px 15px;
}

.LotsAuction .outbid-msg {
    background: #ffe1e1;
    color: #df0d0d;
}

.LotsAuction .hb-msg {
    background: #e0fbe2;
    color: #00910e;
}

.LotsAuction .lv-timer-new {
    margin: 10px auto;
}

.LotsAuction .lv-timer-new img {
    height: 75px;
}

.LotsAuction .sbid-price {
    color: var(--primColor);
    font-size: 24px;
    font-weight: 600;
}

.LotsAuction .lineItemBidTable .table thead th {
    font-size: 13px;
}

.LotsAuction .lineItemBidTable .table td,
.LotsAuction .lineItemBidTable .table th {
    font-size: 13px;
    vertical-align: top;
}

.LotsAuction .lineItemBidTable .sl-pbids {
    width: auto;
}

.LotsAuction .lineItemBidTable .sl-pbids .input-group {
    width: 215px;
}

.LotsAuction .lineItemBidTable .sl-pbids .pink-btn.pbtn-sm {
    margin-left: 10px;
    font-size: 14px;
    width: 130px;
    min-width: 100px !important;
}

.LotsAuction .descBox {
    position: relative;
}

.LotsAuction .descBox .descContent {
    background: #e2e2e2;
    color: #444;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    max-width: 400px;
    height: auto;
    position: absolute;
    top: 10px;
    left: 80px;
    visibility: hidden;
    text-align: left;
    z-index: 1000;
}

.LotsAuction .descBox .descLabel {
    cursor: pointer;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.LotsAuction .descBox:hover .descContent {
    visibility: visible;
}

.LotsAuction .prodInfoSlider .slidImg {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.LotsAuction .prodInfoSlider .favoriteCheck {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: #dbdbdb;
}

.LotsAuction .siteAds.mobileView {
    display: none;
}

.LotsAuction .prodInfoSlider .favoriteCheck label {
    color: var(--primColor);
}

.LotsAuction .prodInfoSlider .plTitle {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    color: #323749;
}

.LotsAuction .prodInfoSlider .pvTitleContainer {
    margin-top: 25px;
}

.LotsAuction .sl-pbids.slidView {
    max-width: 450px;
    margin-top: 15px;
    width: 100%;
}

.LotsAuction .sl-pbids.slidView.notLogged {
    width: auto !important;
}

.LotsAuction .sl-pbids.slidView .input-group-append,
.LotsAuction .sl-pbids.slidView .form-control,
.LotsAuction .sl-pbids.slidView button {
    height: 50px;
}

.LotsAuction .sl-pbids.slidView button {
    width: 200px;
    margin: initial;
}

.LotsAuction .sl-pbids.slidView .input-group {
    margin-right: 15px;
    width: 200px;
}

.LotsAuction .sl-pbids.slidView .input-group span {
    padding: 12px;
}

.LotsAuction .sl-pbids.slidView .input-group p.entAm {
    margin-top: 10px;
}

.LotsAuction .prodInfoSlider .pv-con span,
.LotsAuction .prodInfoSlider .svInfo span {
    font-weight: 500;
    font-size: 16px;
}

.Liquidation .slidView .biddingCnt > div {
    width: 100%;
}

.LotsAuction .sl-pbids.slidView button {
    width: 100%;
}

.LotsAuction .prodInfoSlider .svInfo {
    font-size: 16px;
    font-weight: 400;
}

.LotsAuction .prodInfoSlider .pv-con {
    margin-bottom: 5px;
    margin-top: 5px;
}

.LotsAuction .prodInfoSlider .displayed-text {
    margin-bottom: 0;
}

.LotsAuction .prodInfoSlider .image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: left;
}

.LotsAuction .prodInfoSlider .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
    object-fit: cover;
}

.LotsAuction .prodInfoSlider .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 400px;
    object-fit: contain;
}

.LotsAuction
    .prodInfoSlider
    .image-gallery-content.fullscreen
    .image-gallery-slide
    .image-gallery-image {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.LotsAuction .sublots-table td.sl-img-wrap img {
    cursor: pointer;
    width: 225px;
    height: 225px;
    object-fit: contain;
}

.LotsAuction .sublots-table .auctionLotTitle::before {
    display: none;
}

.LotsAuction .sublots-table .auctionDespn.auctionLotTitle::before,
.LotsAuction .sublots-table .auctionLotLot::before {
    display: block;
}

.LotsAuction .sublots-table td.auctionLotTitle {
    font-size: 13px;
    font-weight: 400;
    margin: 10px 0;
}

.LotsAuction .sublots-table td.sl-img-wrap img {
    object-fit: contain;
}

.LotsAuction .subLotLine .slAct {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    max-width: 350px;
}

.LotsAuction .subLotLine .slAct .biddingCnt .customInput {
    margin-bottom: 15px;
}

.LotsAuction .subLotLine .slAct .biddingCnt .customInput fieldset,
.LotsAuction .slidView .biddingCnt .customInput fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.LotsAuction .subLotLine .slAct .biddingCnt button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 160px;
    padding: 6px 12px;
}

.LotsAuction .slidView .biddingCnt button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.LotsAuction .timer-wrapper .li-timer {
    font-size: 16px;
    font-weight: 700;
}

.LotsAuction .timer-wrapper .li-timer .closedText {
    text-align: center;
    display: block;
}

.LotsAuction .ndCTA {
    display: inline-block;
    position: relative;
    text-align: center;
}

.LotsAuction .ndCTA .primButton button,
.LotsAuction .ndCTA .secButton button {
    width: 200px;
}

.LotsAuction .ndCTA .arrow {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.LotsAuction .ndCTA .arrow span {
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 5px solid var(--primColor);
    border-right: 5px solid var(--primColor);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.LotsAuction .ndCTA .arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.LotsAuction .ndCTA .arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

.LotsAuction .pvMiscActions {
    display: flex;
    background: #e8e8e8;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    padding: 0px 15px;
    margin-bottom: 10px;
}

.LotsAuction .pvMiscActions a,
.LotsAuction .prodInfoSlider .favoriteCheck {
    border-radius: 0 !important;
    background: transparent !important;
    margin-left: 0;
}

.LotsAuction .pvMiscActions a:not(:only-child) {
    border-right: 1px solid #d7d7d7;
}

.LotsAuction .auctionCnt .loadingCnt {
    display: block;
}

.LotsAuction .auctionCnt .loadingCnt .skeletonWrapper {
    box-shadow: none;
    margin: 10px;
    padding: 0;
}

.LotsAuction .sublots-table .subLotLine .slAct {
    width: 100%;
    max-width: initial;
}

.LotsAuction .sublots-table .subLotLine .addCart .secButton {
    width: 100%;
}

.LotsAuction .sublots-table .subLotLine .addCart .secButton button {
    height: 40px;
    width: 100%;
    color: var(--secColor);
    border-color: var(--secColor);
}

.LotsAuction .sublots-table .subLotLine .addCart .secButton button:hover {
    background: var(--secColor);
    color: #fff;
}

.LotsAuction .timer-wrapper .li-timer .timerCnt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.LotsAuction .loa-checkbox-label.active {
    color: var(--primColor) !important;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

@media (max-width: 1200px) {
    .LotsAuction .product-media-wrapper .media-body {
        width: 100%;
        margin: 20px 0;
    }

    .LotsAuction .product-media-wrapper .media-left {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
    }

    .LotsAuction .ndCTA .arrow {
        display: none;
    }

    .LotsAuction .lineItemBidTable .table .biddingCnt {
        flex-wrap: wrap;
    }

    .LotsAuction .lineItemBidTable .slAct .biddingCnt > div {
        width: 100%;
    }

    .LotsAuction .lineItemBidTable .slAct .biddingCnt .customInput {
        margin-bottom: 10px;
        width: 100%;
    }

    .LotsAuction .lineItemBidTable .slAct .biddingCnt .primButton {
        width: 100%;
        margin-bottom: 15px;
    }

    .LotsAuction .lineItemBidTable .slAct .biddingCnt .primButton button {
        width: 100%;
    }

    .LotsAuction .lineItemBidTable .subLotLine .slAct {
        max-width: initial;
    }

    .LotsAuction .lineItemBidTable .sl-pbids .actCnt {
        max-width: initial !important;
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .LotsAuction .product-media-wrapper {
        display: block;
    }

    .LotsAuction
        .pv-media.product-media-wrapper
        .image-gallery-content
        .image-gallery-slide
        .image-gallery-image {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .LotsAuction .sublots-table .auctionLotLot {
        padding-left: 40px !important;
        font-size: 17px !important;
        font-weight: 700 !important;
    }

    .LotsAuction .sublots-table .auctionLotLot::before {
        color: #000 !important;
        font-weight: 600;
    }

    .LotsAuction .timer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto;
        background: #e8e8e8;
        padding: 10px 5px;
        justify-content: center;
    }

    .LotsAuction .li-timer-wrap .timerCnt > div,
    .LotsAuction .li-timer-wrap .closedText {
        font-size: 12px;
        margin: 0;
        grid-column-gap: 5px;
        column-gap: 5px;
    }

    .LotsAuction .prodInfoSlider .pvTitleContainer {
        flex-direction: column-reverse;
        align-items: flex-start !important;
        margin-bottom: 10px;
    }

    .LotsAuction .timer-wrapper .lv-timer-new {
        margin: 0 10px 0 0;
        height: 30px;
    }

    .LotsAuction .timer-wrapper .li-timer {
        font-weight: 500;
        font-size: 14px;
        color: #2c2c37;
        margin-bottom: 0;
    }

    .LotsAuction .timer-wrapper .lv-timer {
        margin: 0 10px 0 0;
        height: 24px;
    }

    .LotsAuction .product-media-wrapper .css-nzyzm1-SkeletonTheme .react-loading-skeleton {
        max-width: 100%;
        max-height: 200px;
    }

    .LotsAuction .pv-media.product-media-wrapper .media-left {
        width: 100%;
        margin: 0 auto;
        max-width: 475px;
    }

    .LotsAuction .sublots-table table,
    .LotsAuction .sublots-table tbody tr,
    .LotsAuction .sublots-table tbody,
    .LotsAuction .sublots-table tbody td {
        display: block !important;
        width: 100%;
    }

    .LotsAuction .sublots-table table thead {
        display: none !important;
    }

    .LotsAuction .sublots-table tbody tr {
        border: 1px solid #dbdbdb;
        padding: 10px 10px 10px 10px;
        position: relative;
    }

    .LotsAuction .sublots-table tbody tr td {
        border: none;
        position: relative;
        padding: 0 0 3px;
    }

    .LotsAuction .sublots-table tbody tr td.sl-img-wrap {
        width: 100%;
        height: 200px;
        margin: 0 auto;
    }

    .LotsAuction .sublots-table td.sl-img-wrap img {
        width: 100%;
        height: 100%;
    }

    .LotsAuction .sublots-table tbody tr td.pro-title {
        padding-right: 30px;
    }

    .LotsAuction .sublots-table tbody tr td.pro-title a {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .LotsAuction .sublots-table tbody tr td.descBox .descLabel {
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 14px;
    }

    .LotsAuction .sublots-table tbody tr td.price-con {
        min-width: 48%;
        display: inline-block !important;
        width: auto;
    }

    .LotsAuction .sublots-table .sl-pbids .row {
        margin: 0;
        flex-wrap: wrap !important;
        position: relative;
    }

    .LotsAuction .sublots-table .sl-pbids .row > div {
        width: 100%;
    }

    .LotsAuction .subLotLine {
        max-width: 100%;
        width: 100%;
    }

    .LotsAuction .subLotLine form {
        width: 100%;
    }

    .LotsAuction .subLotLine .slAct:first-child {
        margin-bottom: 15px;
    }

    .LotsAuction .sublots-table .sl-pbids .row .flex-wrap {
        width: auto !important;
        padding-right: 80px;
    }

    .LotsAuction .sublots-table .sl-pbids .input-group {
        margin: 0 0 10px;
        width: 100% !important;
    }

    .LotsAuction .sublots-table .sl-pbids .row .input-group-append {
        padding: 5px 5px;
        height: 34px;
        background: #f8f8f8;
        width: 40px;
        border: 1px solid #ccc;
        border-radius: 4px 0 0 4px;
        font-size: 12px;
    }

    .LotsAuction .sublots-table .sl-pbids .row .form-control {
        height: 34px;
        margin-left: -1px;
        font-size: 13px;
    }

    .LotsAuction .sublots-table .sl-pbids .row > div.pbid-btn-wrap {
        position: absolute;
        width: 75px;
        right: 0;
        top: 0;
    }

    .LotsAuction .sl-pbids.slidView button,
    .LotsAuction .lineItemBidTable .sl-pbids .pink-btn.pbtn-sm {
        margin-left: 0;
        font-size: 13px;
        width: 100%;
        min-width: 100% !important;
        display: block;
        max-width: 100%;
        padding: 5px;
        height: 34px;
    }

    .LotsAuction .sublots-table tbody tr td.nob-wrap {
        position: absolute;
        width: auto;
        right: 10px;
        top: 10px;
    }

    .LotsAuction .descBox .descContent {
        left: 0;
        right: 0;
        width: 100%;
    }

    .LotsAuction .lineItemBidTable .responsiveView {
        padding-left: 120px;
        font-weight: 500;
    }

    .LotsAuction .lineItemBidTable .responsiveView:before {
        position: absolute;
        content: attr(data-title) ':';
        font-weight: 400;
        left: 0;
        top: 0;
        color: #606060;
    }

    .LotsAuction .sl-pbids.slidView button,
    .LotsAuction .lineItemBidTable .sl-pbids .pink-btn.pbtn-sm {
        height: auto;
        min-width: max-content !important;
    }

    .LotsAuction .subLotLine .slAct {
        justify-content: left;
        width: 100%;
    }

    .LotsAuction .sublots-table .sl-pbids .actCnt {
        padding-right: 10px !important;
        max-width: 150px;
    }

    .LotsAuction .sublots-table .pbid-btn-wrap button {
        height: 34px !important;
    }

    .LotsAuction .subLotLine .slAct .pbid-btn-wrap {
        width: auto !important;
        position: relative !important;
    }
}

.LotsAuction .auction-view-title {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    column-gap: 5px;
    max-width: 940px;
    flex-wrap: wrap;
    color: #333;
    margin-bottom: 15px;
}

.LotsAuction .auction-view-title .pv-title {
    color: #333;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.LotsAuction .av-timer-wrap {
    display: flex;
    align-items: center;
    color: #f20505;
    column-gap: 10px;
}

.LotsAuction .av-timer-wrap .timerCnt > div {
    display: flex;
    align-items: center;
}

.LotsAuction .bidding-se-time {
    display: flex;
    align-items: center;
}

.LotsAuction .bidding-se-time .pv-con {
    color: var(--primColor);
    padding: 0 20px;
}

.LotsAuction .bidding-se-time .pv-con:first-child {
    padding: 0 20px 0 0;
}

.LotsAuction .bidding-se-time .pv-con span {
    font-weight: 500;
}

.LotsAuction .bidding-se-time .pv-con + .pv-con {
    border-left: 1px solid #ececec;
}

.LotsAuction .av-details-tabs {
    display: flex;
    align-items: center;
    background: var(--secColor);
    color: #fff;
    height: 62px;
    justify-content: space-between;
    text-align: center;
}

.LotsAuction .av-details-tabs > a {
    width: 33.3%;
    display: flex;
    column-gap: 20px;
    align-items: center;
    height: 62px;
    justify-content: center;
    cursor: pointer !important;
    text-transform: uppercase;
}

.LotsAuction .av-details-tabs > a + a {
    border-left: 1px solid #fff;
}

.LotsAuction .results-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LotsAuction .rpp-wrap {
    display: flex;
    align-items: center;
}

.LotsAuction .rpp-wrap select {
    border: 1px solid #e0e0e0;
    font-size: 12px;
    padding: 8px 12px;
    border-radius: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuOTI4NzEgNi4wNzEyOUw4LjA3MTA2IDEyLjIxMzYiIHN0cm9rZT0iIzRGNEY0RiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTguMDcxMjkgMTIuMjEzOUwxNC4yMTM2IDYuMDcxNTEiIHN0cm9rZT0iIzRGNEY0RiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==)
        no-repeat right 10px center;
    background-size: 12px;
    padding-right: 20px;
    cursor: pointer;
}

.LotsAuction .rpp-wrap.filters-av {
    margin: 38px 0;
    padding: 10px 0;
    column-gap: 15px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.LotsAuction .rpp-wrap.filters-av a.clear {
    display: flex;
    font-size: 12px;
    line-height: 18px;
    padding: 3px 5px;
    color: #333333;
    cursor: pointer;
    text-decoration: none;
}

.LotsAuction .rpp-wrap.filters-av a.clear .material-icons {
    margin: 0 0 0 5px;
    line-height: 19px;
    font-size: 12px;
    height: -moz-fit-content;
    height: fit-content;
    color: #000;
    font-weight: 600;
}

.LotsAuction .rpp-wrap.filters-av a.advance-settings {
    border: 1px solid #e0e0e0;
    font-size: 12px;
    padding: 5px 12px;
    border-radius: 20px;
    min-width: 155px;
    text-align: center;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: var(--primColor);
    text-decoration: none !important;
}

.LotsAuction .dates-time-wrapper {
    margin-bottom: 40px;
}

.LotsAuction .pop-dt-head,
.LotsAuction .dates-time-wrapper p {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: #000;
    margin-bottom: 5px;
}

.LotsAuction .dates-time-wrapper p {
    font-size: 14px;
    font-weight: 400;
}

.LotsAuction .auction-details {
    display: flex;
    justify-content: space-between;
}

.LotsAuction .auction-details .auction-filter {
    width: 261px;
    opacity: 1;
    transition: width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.LotsAuction .auction-details .auction-filter.hide-af-menu {
    width: 0;
    opacity: 0;
}

.LotsAuction .auction-details .auction-wrapper {
    width: calc(100% - 330px);
    overflow: hidden;
}

.LotsAuction .auction-details .auction-filter.hide-af-menu ~ .auction-wrapper {
    width: 100%;
}

.LotsAuction .av-filter-toggler {
    width: 110px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    column-gap: 20px;
    height: 40px;
    cursor: pointer;
}

.LotsAuction
    .auction-filter
    .RadioBox
    .MuiFormControlLabel-root
    + .MuiFormControlLabel-root
    .MuiRating-icon {
    color: #95c94c;
}

.LotsAuction .auction-filter .RadioBox .MuiFormControlLabel-root:first-child .MuiRating-icon {
    color: #5ec26a;
}

.LotsAuction
    .auction-filter
    .RadioBox
    .MuiFormControlLabel-root
    + .MuiFormControlLabel-root
    .MuiRating-icon.MuiRating-iconEmpty {
    color: #000;
}

.LotsAuction .auction-filter .filterPanel .filterCheck .customCheckbox .MuiCheckbox-root,
.LotsAuction .auction-filter .filterPanel .filterCheck .MuiFormControlLabel-root,
.LotsAuction .auction-filter .filterPanel .filterCheck .MuiListItem-button {
    cursor: pointer;
    background: transparent;
}

.LotsAuction .filterPanel .filterCheck > div {
    width: 100%;
}

@media (max-width: 767px) {
    .LotsAuction .auction-details {
        flex-wrap: wrap;
    }

    .LotsAuction .auction-details .auction-wrapper {
        width: 100%;
    }

    .LotsAuction .auction-view-title,
    .LotsAuction .auction-view-title .pv-title {
        font-size: 18px;
        line-height: 22px;
    }

    .LotsAuction .av-details-tabs {
        padding: 0 5px;
    }
    .LotsAuction .av-details-tabs > a {
        width: 50%;
        line-height: 1.25;
        height: auto;
        padding: 5px 10px;
        font-size: 12px;
        text-align: left;
        justify-content: flex-start;
    }
    .LotsAuction .av-details-tabs > a:last-child {
        border-left: none;
        border-top: 1px solid #fff;
        width: 100%;
        padding-right: 0;
        justify-content: center;
    }

    .LotsAuction .av-details-tabs > a img {
        width: 26px;
    }
    .LotsAuction .auction-wrapper .auctionsMiscFilter .gridListToggle {
        position: relative;
        top: -4px;
    }

    .LotsAuction .rpp-wrap.filters-av a.advance-settings {
        margin: 0 auto 10px 0;
    }

    .LotsAuction .rpp-wrap.filters-av {
        position: relative;
        padding: 0;
        flex-direction: column-reverse;
    }

    .LotsAuction .rpp-wrap.filters-av > div.d-flex select {
        width: 140px;
    }

    .LotsAuction .rpp-wrap.filters-av > div.d-flex {
        width: 100% !important;
    }

    .LotsAuction .multisellerListCard {
        padding: 10px;
    }

    .LotsAuction .auction-details .multisellerListCard.lotCard .grid-img-wrap {
        height: auto;
        width: 88px !important;
        cursor: pointer;
        max-height: 140px;
    }

    .LotsAuction .auction-details .multisellerListCard.lotCard .grid-img-wrap .grid-loc {
        top: 0;
        left: 0;
    }

    .LotsAuction
        .auction-details
        .multisellerListCard.lotCard
        .grid-img-wrap
        .lazy-load-image-background {
        width: inherit !important;
        margin-top: 35px;
        max-height: 88px;
    }

    .LotsAuction .auction-details .multisellerListCard .mslDesc {
        border: none;
        width: calc(100% - 100px) !important;
        margin: 0;
        padding: 0;
    }

    .LotsAuction .auction-details .lv-bid-details .gcTimer,
    .LotsAuction .auction-details .lv-bid-details .galleryPrice {
        width: 100%;
        font-size: 12px;
    }

    .LotsAuction .gcTimer .timerCnt > div span.actualTimer {
        font-size: 12px;
    }

    .LotsAuction .liquidationBuyCard .lv-bid-btns .gridItemAct {
        flex-wrap: wrap;
        gap: 10px;
        grid-gap: 10px;
    }

    .LotsAuction .auction-details .auction-filter {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .LotsAuction .av-details-tabs {
        padding: 0;
        height: auto;
        flex-wrap: wrap;
    }

    .LotsAuction .av-timer-wrap,
    .LotsAuction .bidding-se-time {
        flex-wrap: wrap;
    }

    .LotsAuction .bidding-se-time .pv-con + .pv-con,
    .LotsAuction .bidding-se-time .pv-con {
        border-left: none;
        padding: 0 !important;
        font-size: 14px;
    }

    .LotsAuction .auction-details .multisellerListCard.lotCard .grid-img-wrap,
    .LotsAuction .auction-details .multisellerListCard .mslDesc {
        width: 100% !important;
    }
}
