.scheduler .schedulerMain {
    margin: 20px 0;
}

.scheduler .schedulerMain .MuiFormControl-root.schedulerDate {
    width: 100%;
}

.scheduler .schedulerMain .MuiFormGroup-root {
    /* display: flex;
  flex-direction: row; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    gap: 10px;
}

.schedulePickupBtn .MuiButton-label {
    display: flex;
    align-items: center;
}

.schedulePickupBtn {
    width: fit-content;
}

.scheduleModal.customDialog .MuiDialog-paper {
    min-width: 750px;
}

.scheduleModal.blockedModal.customDialog .MuiDialog-paper {
    max-width: 850px;
    min-width: 750px;
}

.scheduler .schedulerMain .MuiFormControl-root.schedulerDate .MuiFormControlLabel-root {
    border: 1px solid #0000004d;
    display: inline-flex;
    border-radius: 12px;
    background: #fff;
    cursor: pointer;
    color: #333;
    height: 90px;
    width: 100%;
    margin: 0;
    margin: 0;
}

.scheduler
    .schedulerMain
    .MuiFormControl-root.schedulerDate
    .MuiFormControlLabel-root
    .MuiTypography-root {
    font-size: 16px;
    font-weight: 600;
}

.scheduler .schedulerMain .MuiFormControl-root.schedulerDate .MuiFormControlLabel-root:hover,
.scheduler .schedulerMain .MuiFormControl-root.schedulerDate .MuiFormControlLabel-root.selected {
    background: var(--primColor);
    color: #fff;
}

.scheduler
    .schedulerMain
    .MuiFormControl-root.schedulerDate
    .MuiFormControlLabel-root.Mui-disabled:hover,
.scheduler
    .schedulerMain
    .MuiFormControl-root.schedulerDate
    .MuiFormControlLabel-root.Mui-disabled.selected {
    background: initial;
    cursor: no-drop;
}

.scheduler .schedulerMain .MuiFormControlLabel-label {
    font-size: 16px;
    width: 100%;
}

.scheduler .schedulerMain .MuiFormControl-root.schedulerDate .MuiRadio-root {
    display: none;
}

.scheduler .dayPreview {
    text-align: center;
    width: 100%;
}

.scheduler .dayPreview span {
    display: block;
    width: 100%;
}

.scheduler .schedulerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.scheduler .schedulerActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.scheduler .schedulerMove {
    width: 35px;
    height: 35px;
    min-width: initial;
    border-radius: 50%;
    color: #151515;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduler .schedulerMove.Mui-disabled {
    color: #a7a7a7;
}

.scheduler .schedulerTime .MuiFormControl-root {
    width: 100%;
}

.scheduler .schedulerTime .MuiFormGroup-root {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
}

.scheduler .schedulerTime .MuiFormGroup-root .MuiFormControlLabel-root {
    margin: 0;
    border: 1px solid #00000033;
    background: #fff;
    border-radius: 10px;
    flex-direction: row-reverse;
    height: 50px;
}

.scheduler .schedulerTime .stTitle {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    margin: 30px 0 20px;
}

.scheduler .schedulerTime .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root {
    padding-left: 15px;
    font-weight: 500;
    font-size: 15px;
}

.scheduler .schedulerTime .MuiFormGroup-root .MuiFormControlLabel-root .MuiRadio-root {
    color: var(--primColor);
}

.schedulePickupBtn {
    color: var(--primColor);
    border: 1px solid var(--primColor);
    border-radius: 10px;
}

.schedulePickupBtn .MuiButton-label {
    text-transform: initial;
}

.schedulePickupBtn .material-icons {
    padding-right: 10px;
}

label {
    width: 100%;
}

.Liquidation .bda-input label {
    width: auto;
}

.cardRadioInput {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.card-input {
    margin: 10px;
    padding: 0px;
}

.card-input:hover {
    cursor: pointer;
}

.cardRadioInput:checked + .card-input {
    box-shadow: 0 0 1px 1px var(--primColor);
}

.customDialog .MuiDialogContent-root {
    max-height: 100vh;
    height: calc(max(100%) - 100px);
}

@media (max-width: 700px) {
    .scheduleModal.customDialog .MuiDialog-paper {
        min-width: 320px;
        max-width: 700px;
    }

    .customDialog .MuiDialogContent-root {
        padding: 8px 20px;
    }
}

@media (max-width: 600px) {
    .scheduler .schedulerTime .MuiFormGroup-root {
        grid-template-columns: 1fr 1fr;
    }
}
